import * as React from "react"
import { Link, type HeadFC, type PageProps } from "gatsby"

import Headers from '../components/Headers'

import Layout from "../components/Layout"
import { Email } from "react-obfuscate-email"


const IndexPage = ({data, pageContext}: any) => {
  const title = pageContext.lang == 'de' ? 'Impressum' : 'Imprint'
  const contactFormLabel = pageContext.lang == 'de' ? 'Kontaktformular' : 'Contact Form'
  const subtitle = pageContext.lang == 'de' ? 'Diese Website wird betrieben durch:' : 'This website is operated by:'
  const cooperation = pageContext.lang == 'de' ? 'In Zusammenarbeit mit:' : 'In cooperation with:'
  return (
    <Layout lang={pageContext.lang}>
        <div className="mainContent">
            <h1>{title}</h1>
            <p>{subtitle}</p>
            <p>netsense GmbH<br/>
            Zollikerstrasse 153<br/>
            8008 Zürich<br/>
            Switzerland
            </p>
            <p><Email email="hello@ephoria.health">E-Mail</Email><br/>
            Tel. +41 44 784 55 00
            </p>
            <p><Link to={`/${pageContext.lang}/contact`}>{contactFormLabel}</Link></p>
            <p></p>
            <p>{cooperation}</p>
            <p>
            IAP Institut für Angewandte Psychologie<br/>
            ZHAW Angewandte Psychologie<br/>
            Pfingstweidstrasse 96<br/>
            8005 Zürich<br/>
            Switzerland
            </p>
            <p><Email email="info.iap@zhaw.ch">E-Mail</Email><br/>
            Tel. +41 58 934 83 33
            </p>
            <p></p>

            <p><small>ephoria&reg; is a registered trademark.</small></p>
        </div>
    </Layout>
  )
}

export default IndexPage

// add headers
export const Head: HeadFC = ({ location, params, data, pageContext }: any ) => {
    let lang = 'en'
    let title = "Imprint"
    if(pageContext.lang == 'de') {
        lang = 'de'
        title = "Impressum"
    }

    return <Headers
      lang={pageContext.lang}
      title={title}
      location={location}
    />
}
